import * as React from "react";
import PropTypes from "prop-types";
import { Snackbar, Stack } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackAlert = ({ open, setOpen, severity, message }) => {
  function handleClickOutside() {
    setOpen(false);
  }

  React.useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // eslint-disable-next-line

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          onClose={handleClose}
          className="snackAlert"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            className="snackAlert"
            onClose={handleClose}
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
};

export default SnackAlert;

SnackAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  severity: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
