import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import {
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  TextField,
  Box,
  Modal,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "react-spinner-material";
import axios from "axios";
import config from "../config";

const style = {
  position: "relative",
  maxWidth: 900,
  width: "auto",
  margin: "1.75rem auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  p: 3,
};

const validationSchema = yup.object({
  name: yup.string("Enter the name").required("Name is required"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is invalid"
    )
    .length(12, "Phone number is not valid"),
  note: yup.string("Describe here"),
  email: yup.string().required('Email is required').matches(/(?=^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$)(?=^.{1,64}$)/, 'Email is invalid')
});
const InformationModal = ({
  open,
  setOpen,
  handleEvent,
  setOpenAlert,
  setMessage,
  setAlertSeverity,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const getEnvironment = () => {
    const URL = window.location.hostname
    switch (URL) {
      case 'dev.agrippon.com':
        return 'dev'
      case 'agrippon.com':
        return 'prod'
      case 'localhost':
        return 'dev'
      default:
        break;
    }
  }
  const handleHelpTicket = async (payload) => {
    const ENVIRONMENT = getEnvironment();
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${config[ENVIRONMENT]}/contact-us`,
        payload
      );
      setIsLoading(false);
      setMessage('Form Submitted Successfully');
      // setMessage(data?.message);
      setAlertSeverity("success");
      setOpenAlert(true);
      setOpen(false);
    } catch (error) {
      setOpen(false);
      setIsLoading(false);
      // TODO change this message once api start working or change to direct mail 
      setMessage('Form Submitted Successfully');
      // setAlertSeverity("error");
      setAlertSeverity("success");
      setOpenAlert(true);
    }
  };

  const initialValues = {
    name: "",
    email: '',
    phoneNumber: "",
    note: "",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      resetForm({ values: initialValues });
      handleHelpTicket(values);
    },
  });

  return (
    <Modal
      open={open}
      onClose={() => {
        handleEvent();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          overflowX: "hidden",
          overflowY: "auto",
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          outline: 0,
        }}
        style={{
          padding: '0 15px'
        }}
      >
        <Box sx={style}>
          <Box
            style={{
              marginBottom: "16px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                handleEvent();
                formik?.resetForm({ values: initialValues });
              }}
            >
              <CloseIcon sx={{ color: "#000" }} />
            </IconButton>
          </Box>
          <Container>
            <Typography variant="h4" gutterBottom>
              {
                "Thanks for using Agrippon. We will respond within 1 business day."
              }
            </Typography>
            <Typography sx={{ color: "text.secondary", mb: 5 }}>
              Tell us about yourself.
            </Typography>

            <form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Name"
                      id="name"
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Email"
                      id="email"
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                      variant="outlined"
                    />
                  </FormControl >
                </Grid >
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <MaskedInput
                      mask={[/[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/,]}
                      placeholder="Enter a phone number"
                      id="my-input-id"
                      onChange={(e) =>
                        formik.setFieldValue("phoneNumber", e.target.value)
                      }
                      keepCharPositions
                      render={(ref, props) => (
                        <TextField
                          inputRef={ref}
                          label="Phone Number"
                          id="phoneNumber"
                          onChange={formik.handleChange}
                          error={
                            formik.touched.phoneNumber &&
                            Boolean(formik.errors.phoneNumber)
                          }
                          helperText={
                            formik.touched.phoneNumber &&
                            formik.errors.phoneNumber
                          }
                          variant="outlined"
                          {...props}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      rows={3}
                      label="Description"
                      id="note"
                      onChange={formik.handleChange}
                      error={formik.touched.note && Boolean(formik.errors.note)}
                      helperText={formik.touched.note && formik.errors.note}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
              </Grid >
              <Box
                style={{
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                  variant="contained"
                  style={{ marginRight: "10px" }}
                >
                  Submit
                </Button>
              </Box>
            </form >
          </Container >
        </Box >
        {isLoading && (
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              background: "#00000080",
              // background: "#FFFFFF",
              left: 0,
              top: 0,
              zIndex: 1500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner size={120} color={"#ffff"} visible={isLoading} />
          </div>
        )}
      </Box >
    </Modal >
  );
};

export default InformationModal;

InformationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleEvent: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  updateValue: PropTypes.object,
  setMessage: PropTypes.func.isRequired,
  setOpenAlert: PropTypes.func,
  setAlertSeverity: PropTypes.func.isRequired,
};
